<template>
  <section :class="$style.blogArticles">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        @click="
          $router.push($options.ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.CREATE)
        "
      >
        Создать
      </el-button>
    </div>
    <el-table :data="articles" stripe>
      <el-table-column prop="name" label="Название статьи" />
      <el-table-column prop="isActive" label="Активный" width="200">
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row?.isActive"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.UPDATE,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row)"
            :view-link="getViewLink(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      background
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      :class="$style.pagination"
      @current-change="getList"
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

import {
  ADDEVENT_ADMIN_ROUTES,
  getRoute,
  ADDEVENT_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

export default {
  mixins: [notifications],
  components: { ActionButtons },
  ADDEVENT_ADMIN_ROUTES,
  PROJECTS,
  ADDEVENT_ROUTES,
  data() {
    return {
      articles: [],
      total: 0,
      page: this.$route.query.page ?? 1,
      limit: 20,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
  },
  async created() {
    await this.getList()
    this.$router.push( {query: {page: this.page}} )
  },
  async updated() {
    if (this.$router.page !== this.page)
      this.$router.push( {query: {page: this.page}} )
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddeventServiceCore.ArticlesActions.getList({
          limit: this.limit,
          page: this.page,
        })

      loading.close()

      if (error) return

      this.articles = value?.data ?? []
      this.total = value?.meta?.count ?? 0
    },

    async remove(article) {
      const isConfirm = confirm(
        `Вы точно хотите удалить статью: ${article.name}?`,
      )
      if (!isConfirm) {
        return
      }

      const { error } =
        await delivery.AddeventServiceCore.ArticlesActions.delete(article.id)

      if (error) {
        this.showNotification('Ошибка удаления статьи', 'error')
        return
      }

      this.showNotification('Статья успешно удалена', 'success')
      await this.getList()
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
    getViewLink(article) {
      if (!article.categories?.length) return ''

      return getRoute({
        route: ADDEVENT_ROUTES.ARTICLES.ARTICLE,
        params: {
          slug: article.slug,
          categorySlug: article.categories?.[0]?.slug,
        },
        site: PROJECTS.ADDEVENTS,
      })
    },
  },
}
</script>

<style lang="scss" module>
.blogArticles {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .pagination {
    @include pagination;
  }
}
</style>
